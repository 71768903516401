import React, { Component } from "react"

/* Constants */
import { env } from "../constants"

/* Components */
import { Picture } from "@alicorn/poker-ui"


/* Fields */
const DEALER_PLACES = ['10', '8', '6', '4', '2']



/* Widget RMP Dealer Cards */
class DealerCards extends Component {

    /* Filter Cards By Unqiue ID */
    getUniqueValues = (array, prop) => {
        return array.filter((element, index, self) =>
            index === self.findIndex(item =>
                item[prop] === element[prop]
            )
        )
    }

    /* Card status */
    status = card => {

        const { game } = this.props

        let status = false

        if (game && game.data) {
            const index = game.data.findIndex(e => parseInt(e.id) === parseInt(card.id))
            if (index > -1) {
                if (game.data[index].status) {
                    status = true
                }
            }
        }

        return status
    }

    /* Draw card image */
    _image = (card, index) => {

        const { game, cards } = this.props
        const dealer = this.getUniqueValues(cards, 'id')
        const status = this.status(card)
        const style = status ? 'rmp--animated-card-image' : ''

        if ((index === 0 && dealer.length > 4) || game !== null) {
            return <Picture className={style} src={`${env.mediapoint}/cards/${card.image}`} alt="Dealer Card" />
        }

        return <Picture src={`${env.mediapoint}/cards/closed.webp`} alt="Dealer Card" />
    }


    /* Draw flip animation box */
    _flip = (card, index) => {

        const { game, cards } = this.props
        const dealer = this.getUniqueValues(cards, 'id')
        const isActive = ((index === 0 && dealer.length > 4) || game !== null) ? 'active' : ''

        return (
            <div className={`rmp--dealer-flip-card ${isActive}`}>
                <div className="rmp--dealer-flip-card-inner">
                    <div className="rmp--dealer-flip-card-front">
                        <Picture src={`${env.mediapoint}/cards/closed.webp`} alt="Dealer Card" />
                    </div>
                    <div className="rmp--dealer-flip-card-back">
                        {this._image(card, index)}
                    </div>
                </div>
            </div>
        )
    }

    /* Draw Status */
    _status = card => {

        const status = this.status(card)

        return (
            <div className={`rmp--dealer-card-status ${status ? 'rmp--dealer-card-status-animation' : ''}`}>
                {status && <div className="rmp--dealer-card-status-corner" />}
            </div>
        )

    }

    /* Draw Mini Status */
    _miniStatus = (card, index) => {

        const status = this.status(card)
        const { cards, game, removedCard } = this.props

        if (game && removedCard && index === 0 && cards.length === 6) {
            return (
                <div className={`rmp--dealer-mini-status ${status ? 'rmp--dealer-card-status-animation' : ''}`}>
                    <div className={`rmp--mini-status active`}>+1</div>
                </div>
            )
        }
    }

    /* Draw Removed card */
    _removed = (card, type = 'class') => {

        const { removedCard } = this.props
        const status = removedCard !== null && parseInt(card.id) === parseInt(removedCard.id)

        if (type === 'class') {
            if (status) {
                return "removed"
            }
            else {
                return ""
            }
        }

        if (status && type === 'jsx') {
            return (
                <div className="rmp--removed-card">
                    <Picture src={`${env.mediapoint}/images/x.png`} alt="X" />
                </div>
            )
        }
    }

    /* Draw card */
    _card = () => {

        const { cards } = this.props
        const dealer = this.getUniqueValues(cards, 'id')

        return (
            <div className="rmp--dealer-box">

                <div className="rmp--dealer-cards">
                    {dealer.map((card, index) => (
                        <div className={`rmp--dealer-card ${this._removed(card)}`} key={`${card.uid}`}>
                            {this._flip(card, index)}
                            {this._removed(card, "jsx")}
                            {this._status(card, index)}
                            {this._miniStatus(card, index)}
                        </div>
                    ))}
                </div>

                <div className="rmp--dealer-cards-places">
                    {DEALER_PLACES.map(item => <div key={`place-${item}`} className={`rmp--dealer-card-place`}></div>)}
                </div>

            </div>
        )
    }


    /* Draw dealer game result */
    _result = () => {

        const { game } = this.props

        if (game !== null) {
            if (parseInt(game.level) === 0) {
                return <div className={`rmp--dealer-result rmp--dealer-result-red`}>Нет игры</div>
            }
            if (parseInt(game.level) > 0) {
                return <div className={`rmp--dealer-result`}>{game.name}</div>
            }
        }

    }


    render = () => (
        <div className="rmp--dealer">
            <h2>Рука дилера</h2>
            {this._card()}
            {this._result()}
        </div>
    )

}

export default DealerCards