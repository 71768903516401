import React, { Component } from 'react'

/* Counter */
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

class Timer extends Component {
    constructor() {
        super()
        this.state = {

        }
    }

    render = () => {

        const { times, stage, hide } = this.props 

        const TiMER_COLORS = ['#64CB6B', '#F7B801', '#A30000', '#A30000']
        const TIMER_TRAIL_COLOR = "rgba(0, 0, 0, 1)"
        const TIMER_SIZE = 120
        const TIMER_DURATION = times[stage]
        const TIMER_COLORS_TIME = [TIMER_DURATION / 4 * 3, TIMER_DURATION / 4 * 2, TIMER_DURATION / 4 * 1, 0]

        
        return (
            <div className="chips-timer-box">
                <CountdownCircleTimer
                    isPlaying
                    size={TIMER_SIZE}
                    duration={TIMER_DURATION}
                    colors={TiMER_COLORS}
                    colorsTime={TIMER_COLORS_TIME}
                    trailColor={TIMER_TRAIL_COLOR}
                    onComplete={() => hide()}
                >
                    {({ remainingTime }) => <p className="timer">{remainingTime}</p>}
                </CountdownCircleTimer>
            </div>
        )

    }
}

export default Timer