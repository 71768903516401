import React, { Component } from "react"


class Waiting extends Component {

    render = () => {
        return (
            <div className="waiting">
                <p>Дождитесь следующей игры</p>
            </div>
        )
    }

}

export default Waiting