import React, { Component } from "react"

/* Constants */
import { env } from "../constants"

/* Components */
import { Picture } from "@alicorn/poker-ui"

/* utils */
import { get_unique_values } from "../constants/utils"

/* Fields */
const PLAYER_SPACES = ['9', '7', '5', '3', '1']
const ADDITIONAL_SPACES = ['15', '14', '13', '12', '+6/11']



/* Widget RMP Player Cards */
class RMPPlayerCards extends Component {

    constructor(props) {
        super(props)

        let cards = []

        if (props.exchangeCards && Array.isArray(props.exchangeCards)) {
            cards = props.exchangeCards
        }

        this.state = {
            activeCards: cards
        }
    }

    /* Action Clear Active Cards */
    clearCards = () => {
        this.setState({ activeCards: [] })
    }

    /* Exchange action */
    exchange = card => {

        const { used, setExchangeCards } = this.props
        const { activeCards } = this.state

        if (!used) {

            let cards = activeCards
            const index = activeCards.findIndex(c => c.id === card.id)

            if (index > -1) {
                cards.splice(index, 1)
            }
            else {
                cards.push(card)
            }

            this.setState({ activeCard: cards })
            setExchangeCards(cards)

        }

    }


    /* Card status */
    status = card => {

        const { game, sixthGame } = this.props

        let status = false

        if (game && game.data) {
            const index = game.data.findIndex(e => parseInt(e.id) === parseInt(card.id))
            if (index > -1) {
                if (game.data[index].status) {
                    status = true
                }
            }
        }

        if (sixthGame && Array.isArray(sixthGame) && sixthGame.length > 0) {
            sixthGame.forEach(item => {
                const index = item.data.findIndex(e => parseInt(e.id) === parseInt(card.id))
                if (index > -1) {
                    if (item.data[index].status) {
                        status = true
                    }
                }
            })
        }

        return status
    }


    /* Draw card image */
    _image = card => {
        const status = this.status(card)
        const style = status ? 'rmp--animated-card-image' : ''
        return <Picture className={style} src={`${env.mediapoint}/cards/${card.image}`} alt="Player Card" />
    }


    /* Draw flip animation box */
    _flip = card => {

        const { activeCards } = this.state
        const index = activeCards.findIndex(a => parseInt(a.id) === parseInt(card.id))

        return (
            <div className={`rmp--player-flip-card ${index === -1 ? 'active' : 'close'}`}>
                <div className="rmp--player-flip-card-inner">
                    <div className="rmp--player-flip-card-front">
                        <Picture src={`${env.mediapoint}/cards/closed.webp`} alt="Player Card" />
                    </div>
                    <div className="rmp--player-flip-card-back">
                        {this._image(card)}
                    </div>
                </div>
            </div>
        )
    }


    /* Draw Status */
    _status = card => {

        const { activeCards } = this.state
        const index = activeCards.findIndex(e => parseInt(e.id) === parseInt(card.id))
        const status = this.status(card)

        return (
            <div className={`rmp--player-game-status ${status ? 'rmp--player-game-status-animation' : 'hide'}`} style={index === -1 ? { opacity: 1 } : { opacity: 0 }}>
                {status && <div className="rmp--player-game-status-corner" />}
            </div>
        )

    }


    /* Exchange card status */
    exchangeStatus = card => {
        const { activeCards } = this.state
        const { isExchange } = this.props
        const index = activeCards.findIndex(e => parseInt(e.id) === parseInt(card.id))
        return index > -1 && isExchange
    }


    /* Draw Player cards */
    _player = () => {

        const { cards, isExchange, used } = this.props
        const tapScreenStatus = cards.length === 5 && !used

        const filtered_cards = get_unique_values(cards, "id")

        return (
            <div className="rmp--player-box">

                <div className={`rmp--player-cards ${isExchange ? 'rmp--player-exchange-cards' : ''}`}>
                    {filtered_cards.map(card =>
                        <div onClick={() => this.exchange(card)} className={`rmp--player-card ${this.exchangeStatus(card) ? 'rmp--player-card-exchange' : ''}  ${tapScreenStatus ? 'rmp--player-card-tap' : ''}`} key={`${card.id}`}>
                            {this._flip(card)}
                            {this._status(card)}
                        </div>
                    )}
                </div>

                <div className="rmp--player-cards-places">
                    {PLAYER_SPACES.map(item => <div key={`place-${item}`} className={`rmp--player-card-place ${tapScreenStatus ? 'rmp--player-card-place-tap' : ''}`}></div>)}
                </div>

            </div>
        )
    }


    /* Draw player game result */
    _result = () => {

        const { game, sixthGame } = this.props

        let result = ""

        if (sixthGame) {

            result = ""

            if (sixthGame && Array.isArray(sixthGame) && sixthGame.length > 0) {
                sixthGame.forEach((sg, index) => {
                    if (index <= 1) {
                        if (parseInt(sg.level) > 0) {
                            if (index === 1) {
                                result = result + " + "
                            }

                            result = result + sg.name
                        }
                    }
                })
            }

            if (result === "") {
                result = "Нет игры"
            }
        }
        else {
            if (game !== null) {
                if (parseInt(game.level) === 0) {
                    result = "Нет игры"
                }
                if (parseInt(game.level) > 0) {
                    result = game.name
                    if (game.hasAceKing) {
                        result = result + " + Туз Король"
                    }
                }
            }
        }

        if (result !== "") {
            if (result === "Нет игры") {
                return <div className={`rmp--player-result rmp--player-result-red`}>{result}</div>
            }
            else {
                return <div className={`rmp--player-result`}>{result}</div>
            }
        }
    }


    /* Draw Player Cards */
    _playerBox = () => {
        return (
            <div className="rmp--player">
                <h2>Рука игрока</h2>
                {this._player()}
                {this._result()}
            </div>
        )
    }





    /* Draw Additional Image */
    _additionalImage = card => {

        const { isSecondStage } = this.props

        if (isSecondStage) {
            const status = this.status(card)
            const style = status ? 'rmp--animated-card-image' : ''
            return <Picture className={style} src={`${env.mediapoint}/cards/${card.image}`} alt="Additonal card" />
        }

        return <Picture src={`${env.mediapoint}/cards/closed.webp`} alt="Additonal card" />
    }


    /* Draw Additonal Flip */
    _additionalFlip = card => {

        const { isSecondStage } = this.props

        return (
            <div className={`rmp--additional-flip-card ${isSecondStage ? 'active' : 'close'}`}>
                <div className="rmp--additional-flip-card-inner">
                    <div className="rmp--additional-flip-card-front">
                        <Picture src={`${env.mediapoint}/cards/closed.webp`} alt="Additonal card" />
                    </div>
                    <div className="rmp--additional-flip-card-back">
                        {this._additionalImage(card)}
                    </div>
                </div>
            </div>
        )
    }


    /* Draw Additonal Number */
    _additionalNumber = index => {

        const { activeCards } = this.state
        const { isSecondStage, isSixth } = this.props
        const i = activeCards.findIndex((_, key) => (4 - index) === key)

        if (isSixth) {
            return <div className={`rmp--additional-card-number ${index === 4 && !isSecondStage ? 'active' : 'close'}`} key={`number-${index}`}>+1</div>
        }

        return <div className={`rmp--additional-card-number ${i > -1 && !isSecondStage ? 'active' : 'close'}`} key={`number-${index}`}></div>
    }

    /* Draw Additional Mini Status */
    _additionalMiniStatus = index => {

        const { activeCards } = this.state
        const { isSecondStage, isSixth, isExchange } = this.props
        const i = activeCards.findIndex((_, key) => (4 - index) === key)

        if (isSixth) {
            return <div className={`rmp--mini-status ${index === 4 && isSecondStage ? 'active' : 'close'}`}>+1</div>
        }

        if (isExchange) {
            return (
                <div className={`rmp--mini-status ${i > -1 && isSecondStage ? 'active' : 'close'}`}>
                    <Picture src={`${env.mediapoint}/images/exchange.png`} alt="Exchange" />
                </div>
            )
        }
    }


    /* Determine additional card status */
    additionalStatus = index => {

        const { isSixth, isExchange } = this.props
        const { activeCards } = this.state

        if (isSixth && index === 4) {
            return "rmp--additional-card-sixth"
        }

        const key = 4 - index

        if (isExchange && key >= 0 && (activeCards.length - 1) >= key) {
            return "rmp--additional-card-exchange"
        }

        return ''

    }


    /* Additional Status */
    _additionalStatus = (card, key) => {

        const { isSecondStage } = this.props
        const { activeCards } = this.state
        const index = activeCards.findIndex(e => parseInt(e.id) === parseInt(card.id))
        const status = this.status(card)

        if (isSecondStage) {
            return (
                <div className={`rmp--additional-game-status ${status ? 'rmp--additional-game-status-animation' : 'hide'}`} style={index === -1 ? { opacity: 1 } : { opacity: 0 }}>
                    {this._additionalMiniStatus(key)}
                    {status && <div className="rmp--additional-game-status-corner" />}
                </div>
            )
        }

    }



    /* Draw Additional Cards */
    _additional = () => {

        const { additional } = this.props

        const filtered_cards = get_unique_values(additional, "id")

        return (
            <div className="rmp--additional-box">

                <div className="rmp--additional-cards">
                    {filtered_cards.map((card, index) =>
                        <div className={`rmp--additional-card ${this.additionalStatus(index)}`} key={`${card.uid}`}>
                            {this._additionalFlip(card)}
                            {this._additionalNumber(index)}
                            {this._additionalStatus(card, index)}
                        </div>
                    )}
                </div>

                <div className="rmp--additional-cards-places">
                    {ADDITIONAL_SPACES.map(item => <div key={`place-${item}`} className="rmp--additional-card-place"></div>)}
                </div>

            </div>
        )
    }

    /* Draw Additional Card Box */
    _additionalBox = () =>
        <div className="rmp--additional">
            <h2>Дополнительные карты</h2>
            {this._additional()}
        </div>


    render = () => {
        return (
            <div className="rmp--player-card-container">
                {this._additionalBox()}
                {this._playerBox()}
            </div>
        )
    }

}

export default RMPPlayerCards